import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axios";

export default function AllPurchase() {
	const [listOfPosts, setListOfPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	// Fetch all posts
	const fetchPosts = async () => {
		try {
			const response = await axiosInstance.get("/purchases");
			setListOfPosts(response.data);
		} catch (error) {
			setError("Error fetching posts.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchPosts();
	}, []); // Empty dependency array to run the effect only once

	return (
		<div>
			{loading ? (
				<p>Loading...</p>
			) : error ? (
				<p>{error}</p>
			) : listOfPosts.length > 0 ? (
				listOfPosts.map((post, index) => <div key={index}>{post.name}</div>)
			) : (
				<div></div>
			)}
		</div>
	);
}

import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import { format } from "date-fns";
import axiosInstance from "../../../api/axios";

import TextInput from "../../../Components/TextInput";
import { WAREHOUSES } from "../../../constants";
import { purchaseSchema } from "../../../Validations/ValidationSchema";

const PurchaseForm = ({ fetchPurchases, setSearchQuery }) => {
  const productCodeInputRef = useRef();
  const [loading, setLoading] = useState(true);
  const [listOfSuppliers, setListOfSuppliers] = useState([]);

  const initialValues = {
      date: format(new Date(), "yyyy-MM-dd"),
      warehouse: "0",
      supplier_id: "1",
      code: "",
      name: "",
      weight: "",
      quantity: "",
      cost: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: purchaseSchema,
    onSubmit: async (values) => {
      await axiosInstance.post("/purchases", values);
      handleResetSpecificFields();
      setSearchQuery("");
      fetchPurchases();
    },
  });

  useEffect(() => {
      productCodeInputRef.current.focus();
      fetchSuppliers();
  }, []);
  

  const fetchSuppliers = async () => {
    try {
        const response = await axiosInstance.get("/users");
        const filteredSuppliers = response.data.filter(
            (user) => user.user_type === "supplier"
        );
        setListOfSuppliers(filteredSuppliers);
    } catch (error) {
        console.log("Error fetching users.");
    } finally {
        setLoading(false);
    }
};

  const handleResetSpecificFields = () => {
    formik.resetForm({
      values: {
        ...formik.values,
        code: "",
        name: "",
        weight: "",
        quantity: "",
        cost: "",
      },
    });
    productCodeInputRef.current.focus();
  };

  const handleResetForm = () => {
    formik.resetForm({ values: initialValues });
    productCodeInputRef.current.focus();
  };

  const handleDateChange = (date) => {
    formik.setFieldValue("date", date ? format(date, "yyyy-MM-dd") : "");
  };

  return (
    <div className="bg-green-300 border border-green-400 shadow-md rounded p-2">
      <form className="w-full mt-3 px-6" onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-8 gap-3">
          <div className="col-span-1 h-24">
            <label className="block uppercase text-xs font-semibold mb-3" htmlFor="date">
              Date
            </label>
            <DatePicker
              className="border border-gray-400 text-sm w-full py-1 px-2 bg-gray-300 text-gray-700 focus:bg-yellow-100 rounded-md shadow-sm"
              dateFormat="yyyy/MM/dd"
              name="date"
              selected={formik.values.date ? new Date(formik.values.date) : null}
              onChange={handleDateChange}
            />
            {formik.errors.date && formik.touched.date && (
              <div className="text-red-500 text-xs">{formik.errors.date}</div>
            )}
          </div>
          <div className="col-span-1 h-24">
            <label className="block uppercase text-xs font-semibold mb-3" htmlFor="warehouse">
              Warehouse
            </label>
            <select
              className="border border-gray-400 w-full text-sm rounded-md shadow-sm py-1 px-1 bg-gray-300 text-gray-700 focus:bg-yellow-100"
              id="warehouse"
              name="warehouse"
              onChange={formik.handleChange}
              value={formik.values.warehouse}
            >
              {WAREHOUSES.map((warehouse) => (
                <option key={warehouse.value} value={warehouse.value}>
                  {warehouse.label}
                </option>
              ))}
            </select>
            {formik.errors.warehouse && formik.touched.warehouse && (
              <div className="text-red-500 text-xs">{formik.errors.warehouse}</div>
            )}
          </div>
          <div className="col-span-1 h-24">
            <label className="block uppercase text-xs font-semibold mb-3" htmlFor="supplier">
              Supplier
            </label>
            <select
              className="border border-gray-400 w-full text-sm rounded-md shadow-sm py-1 px-1 bg-gray-300 text-gray-700 focus:bg-yellow-100"
              id="supplier"
              name="supplier_id"
              onChange={formik.handleChange}
              value={formik.values.supplier_id}
            >
              {loading && <option value="">Suppliers...</option>}
              {!loading && listOfSuppliers.length === 0 && (
                <option value="" disabled>
                  No Suppliers
                </option>
              )}
              {!loading &&
                listOfSuppliers.map((supplier) => (
                  <option key={supplier.email} value={supplier.id}>
                    {supplier.name}
                  </option>
                ))}
            </select>
            {formik.errors.supplier_id && formik.touched.supplier_id && (
              <div className="text-red-500 text-xs">{formik.errors.supplier_id}</div>
            )}
          </div>
          <div className="col-span-1 h-24">
            <label className="block uppercase text-xs font-semibold mb-3" htmlFor="code">
              Product Code
            </label>
            <TextInput
              id="code"
              type="text"
              className="mt-1 text-sm block w-full"
              placeholder="Code"
              ref={productCodeInputRef}
              name="code"
              onChange={formik.handleChange}
              value={formik.values.code}
            />
            {formik.errors.code && formik.touched.code && (
              <div className="text-red-500 text-xs">{formik.errors.code}</div>
            )}
          </div>
          <div className="col-span-1 h-24">
            <label className="block uppercase text-xs font-semibold mb-3" htmlFor="name">
              Product Name
            </label>
            <TextInput
              id="name"
              type="text"
              className="mt-1 text-sm block w-full"
              placeholder="Name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            {formik.errors.name && formik.touched.name && (
              <div className="text-red-500 text-xs">{formik.errors.name}</div>
            )}
          </div>
          <div className="col-span-1 h-24">
            <label className="block uppercase text-xs font-semibold mb-3" htmlFor="weight">
              Weight (ct)
            </label>
            <TextInput
              id="weight"
              type="text"
              className="mt-1 text-sm block w-full"
              placeholder="Weight"
              name="weight"
              onChange={formik.handleChange}
              value={formik.values.weight}
            />
            {formik.errors.weight && formik.touched.weight && (
              <div className="text-red-500 text-xs">{formik.errors.weight}</div>
            )}
          </div>
          <div className="col-span-1 h-24">
            <label className="block uppercase text-xs font-semibold mb-3" htmlFor="quantity">
              Quantity (Pc)
            </label>
            <TextInput
              id="quantity"
              type="text"
              className="mt-1 text-sm block w-full"
              placeholder="Quantity"
              name="quantity"
              onChange={formik.handleChange}
              value={formik.values.quantity}
            />
            {formik.errors.quantity && formik.touched.quantity && (
              <div className="text-red-500 text-xs">{formik.errors.quantity}</div>
            )}
          </div>
          <div className="col-span-1 h-24">
            <label className="block uppercase text-xs font-semibold mb-3" htmlFor="cost">
              Cost (MMK)
            </label>
            <TextInput
              id="cost"
              type="text"
              className="mt-1 text-sm block w-full"
              placeholder="Cost"
              name="cost"
              onChange={formik.handleChange}
              value={formik.values.cost}
            />
            {formik.errors.cost && formik.touched.cost && (
              <div className="text-red-500 text-xs">{formik.errors.cost}</div>
            )}
          </div>
        </div>
        <div className="flex justify-between mb-3">
          <div></div>
          <div className="flex items-center space-x-1.5">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 bg-red-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-red-700 disabled:opacity-25 transition ease-in-out duration-150"
              onClick={handleResetForm}
            >
              Reset
            </button>
            <button
              type="submit"
              className="ml-2 inline-flex items-center px-4 py-2 bg-indigo-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-indigo-700 disabled:opacity-25 transition ease-in-out duration-150"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PurchaseForm;

import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";

export default function Home() {
	const clickSound = useRef(new Audio("/sound/mixkit-on-or-off-light-switch-tap-2585.wav"));

	useEffect(() => {
		console.log("Audio element loaded:", clickSound.current);
	}, []);

	// const playClickSound = () => {
	// 	clickSound.current.play()
	// 		.then(() => {
	// 			console.log("Audio played successfully");
	// 		})
	// 		.catch(error => {
	// 			console.error("Error playing audio:", error);
	// 		});
	// };

	const cards = [
		{ route: "/purchase/create", label: "purchase" },
		{ route: "/sale/create", label: "sale" },
		{ route: "/purchase/all", label: "User" },
	];

	return (
		<div className="flex items-center justify-center min-h-screen bg-gray-300 p-4">
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
				{cards.map((card, index) => (
					<Link to={card.label === 'purchase' ? card.route : '#'} key={index}>
						<div className="bg-white p-6 rounded-lg shadow-lg cursor-pointer hover:shadow-xl transition-shadow duration-300">
							<h5 className="text-xl font-bold mb-2 capitalize">{card.label}</h5>
							<p className="text-gray-700">This is {card.label}.</p>
						</div>
					</Link>
				))}
			</div>
		</div>
	);
}

import React from "react";

const ModalBox = ({ children, className = '' }) => {
	return (
		<div className={`fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-70`}>
			<div className={`bg-white rounded shadow-lg p-6 ${className}`}>
				{children}
			</div>
		</div>
	);
};

export default ModalBox;

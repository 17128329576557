import React, { forwardRef } from "react";


const TextInput = forwardRef(({ id, type = 'text', className, placeholder, ...props }, ref) => {
  return (
    <input
      id={id}
      type={type}
      className={`border-gray-400 text-sm py-1 px-2 border text-gray-700 focus:bg-yellow-100 rounded-md shadow-sm ${className}`}
      ref={ref}
      placeholder={placeholder}
      {...props}
    />
  );
});

export default TextInput;

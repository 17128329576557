import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DateRangeIcon from "@mui/icons-material/DateRange";
import axiosInstance from "../../../api/axios";

import WarehouseModdal from "./WarehouseModal";
import TextInput from "../../../Components/TextInput";

export default function Index() {
	const [modalOpen, setModalOpen] = useState(false);
	const [listOfPurchases, setListOfPurchases] = useState([]);
	const [currentWarehouse, setCurrentWarehouse] = useState("");

	const [totalPurchases, setTotalPurchases] = useState("0.00");
	const [totalPurchaseCost, setTotalPurchaseCost] = useState("0.00");
    
	const [currentPage, setCurrentPage] = useState();
	const [lastPage, setLastPage] = useState();

    const handlePageChange = (pageNum) => {
		setCurrentPage(pageNum);
		fetchPurchases(pageNum);
	};

	const renderPageButtons = () => {
		const pageNumbers = [];
		const maxPageButtons = 5;
		let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
		let endPage = Math.min(lastPage, startPage + maxPageButtons - 1);

		if (endPage - startPage < maxPageButtons - 1) {
			startPage = Math.max(1, endPage - maxPageButtons + 1);
		}

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}

		return pageNumbers.map((pageNum) => (
			<button
				key={pageNum}
				onClick={() => handlePageChange(pageNum)}
				className={`px-2 py-1 bg-gray-300 rounded-md text-sm ${
					currentPage === pageNum
						? "bg-gray-900 text-white"
						: "hover:bg-gray-700 hover:text-white text-gray-700"
				}`}
			>
				{pageNum}
			</button>
		));
	};

    const fetchPurchases = async (pageNum = 1, searchQuery = "") => {
        try {
            const response = await axiosInstance.get("/purchases", {
                params: {
                    page: pageNum,
                    search: searchQuery,
                    sortColumn: "code",
                    sortOrder: "ASC",
                    warehouse: currentWarehouse,
                },
            });
            setListOfPurchases(response.data.posts);

            // Set overall report
            setTotalPurchases(response.data.totalItems);
            setTotalPurchaseCost(parseFloat(response.data.totalCost).toFixed(2));

            setCurrentPage(response.data.currentPage);
            setLastPage(response.data.lastPage);
        } catch (error) {
            console.log("Error fetching purchases.");
        }
    };

	useEffect(() => {
		fetchPurchases();
	}, [ currentWarehouse ]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<WarehouseModdal
				isOpen={modalOpen}
				onCancel={() => setModalOpen(false)}
				setCurrentWarehouse={setCurrentWarehouse}
			/>
			<div className="flex space-x-2 justify-between">
				<label
					className="text-gray-700 text-lg font-semibold mb-3 block"
					htmlFor="cost"
				>
					Purchase Report ({" "}
					{`${
						currentWarehouse === ""
							? "All Warehouses"
							: currentWarehouse === "0"
							? "Mogok"
							: "Mandalay"
					}`}{" "}
					)
				</label>
				<div className="flex space-x-0.5 py-1">
					<button
						onClick={() => setModalOpen(true)}
						className="flex text-sm items-center px-4 rounded-tl-md rounded-bl-md bg-red-500 text-white hover:bg-red-600"
					>
						<FilterAltIcon className="mr-1" sx={{ fontSize: "18px" }} />
						Warehouse
					</button>
					<button className="flex text-sm items-center px-4 rounded-tr-md rounded-br-md bg-indigo-600 text-white hover:bg-indigo-700">
						<DateRangeIcon className="mr-1" sx={{ fontSize: "16px" }} />
						Date
					</button>
				</div>
			</div>

			<div className="p-4 rounded border border-gray-300 bg-white shadow-md">
				<div className="grid grid-cols-6 gap-3">
					<div className="col-span-1 py-2 px-3 border border-blue-200 bg-gray-50 rounded-sm shadow-sm">
						<span className="text-gray-400 text-sm block">{`Total Items`}</span>
						<span className="text-gray-700 text-lg font-semibold">
							{`${totalPurchases}`}
						</span>
					</div>
					<div className="col-span-1 py-2 px-3 border border-blue-200 bg-gray-50 rounded-sm shadow-sm">
						<span className="text-gray-400 text-sm block">
							{`Total Values (MMK)`}
						</span>
						<span className="text-gray-700 text-lg font-semibold">
							{`${totalPurchaseCost}`}
						</span>
					</div>
				</div>

				<br />
				<div className="grid grid-cols-6 gap-3">
					<TextInput
						type="text"
						className="col-span-1 text-sm py-1 text-gray-700"
						placeholder="Search"
						// value={searchQuery}
						// onChange={(e) => setSearchQuery(e.target.value)}
					/>
				</div>

				<table className="border-collapse w-full border border-slate-400 mt-3">
					<thead>
						<tr className="bg-gray-300">
							<th className="border border-slate-500 py-1 px-3 text-left w-36">
								Date
							</th>
							<th className="border border-slate-500 py-1 px-3 text-left w-48">
								Code
							</th>
							<th className="border border-slate-500 py-1 px-3 text-left w-52">
								Name
							</th>
							<th className="border border-slate-500 py-1 px-3 text-right w-36">
								Weight
							</th>
							<th className="border border-slate-500 py-1 px-3 text-right w-36">
								Qty
							</th>
							<th className="border border-slate-500 py-1 px-3 text-right w-36">
								Cost
							</th>
							<th className="border border-slate-500 py-1 px-3 text-right w-36">
								Warehouse
							</th>
							<th className="border border-slate-500 py-1 px-3 text-left w-36">
								Supplier
							</th>
						</tr>
					</thead>
					<tbody>
						{listOfPurchases.length === 0 ? (
							<tr>
								<td colSpan={8} className="text-center py-4">
									No Purchases found.
								</td>
							</tr>
						) : (
							listOfPurchases.map((purchase, index) => (
								<tr key={purchase.id} className="hover:bg-gray-100">
									<td className="border border-slate-500 py-1 px-3">
										{purchase.date}
									</td>
									<td className="border border-slate-500 py-1 px-3">
										{purchase.code}
									</td>
									<td className="border border-slate-500 py-1 px-3">
										{purchase.name}
									</td>
									<td className="border border-slate-500 py-1 px-3 text-right">
										{purchase.weight} <span className="ml-2">ct</span>
									</td>
									<td className="border border-slate-500 py-1 px-3 text-right">
										{purchase.quantity} <span className="ml-2">pcs</span>
									</td>
									<td className="border border-slate-500 py-1 px-3 text-right">
										{purchase.cost}
									</td>
									<td className="border border-slate-500 py-1 px-3 text-right">
										{purchase.warehouse === "0" ? "Mogok" : "Mandalay"}
									</td>
									<td className="border border-slate-500 py-1 px-3">
										{purchase.supplier.name}
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>

                <div className="flex justify-between items-center mt-6">
					{listOfPurchases.length !== 0 && (
						<>
							<span className="text-gray-600 px-6 text-sm">
								Page ( {currentPage} of {lastPage} )
							</span>
							<div className="flex items-center space-x-1.5">
								<button
									onClick={() => handlePageChange(1)}
									className={`px-2 py-1 rounded-md text-sm ${
										currentPage === 1
											? "bg-gray-300 pointer-events-none text-gray-400"
											: "bg-gray-500 hover:bg-gray-700 text-white"
									}`}
								>
									First
								</button>
								<button
									onClick={() => handlePageChange(currentPage - 1)}
									className={`px-2 py-1 rounded-md text-sm ${
										currentPage === 1
											? "bg-gray-300 pointer-events-none text-gray-400"
											: "bg-gray-500 hover:bg-gray-700 text-white"
									}`}
								>
									Previous
								</button>
								{renderPageButtons()}
								<button
									onClick={() => handlePageChange(currentPage + 1)}
									className={`px-2 py-1 rounded-md text-sm ${
										currentPage === lastPage
											? "bg-gray-300 pointer-events-none text-gray-400"
											: "bg-gray-500 hover:bg-gray-700 text-white"
									}`}
								>
									Next
								</button>
								<button
									onClick={() => handlePageChange(lastPage)}
									className={`px-2 py-1 rounded-md text-sm ${
										currentPage === lastPage
											? "bg-gray-300 pointer-events-none text-gray-400"
											: "bg-gray-500 hover:bg-gray-700 text-white"
									}`}
								>
									Last
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function LeftSidebar({ buttons = [] }) {
	const navigate = useNavigate();
	const location = useLocation();
	const [activeRoute, setActiveRoute] = useState("");

	useEffect(() => {
		// Initialize the activeRoute based on the current location
		setActiveRoute(location.pathname);
	}, [location.pathname]);

	const handleClick = (route) => {
		setActiveRoute(route);
		navigate(route);
	};

	return (
		<nav className="w-40 p-4 space-y-2 bg-gray-300 shadow-inner" style={{ minHeight: `calc(100vh - 56px)` }}>
			{buttons.map((button, index) => (
				<button
					key={index}
					onClick={() => handleClick(button.route)}
					className={`block py-1 px-4 rounded-md hover:bg-green-700 hover:text-white w-full text-left ${
						activeRoute === button.route ? "bg-green-600 border border-green-700 text-white font-semibold" : "bg-gray-50 border border-gray-400"
					}`}
				>
					{button.label}
				</button>
			))}
		</nav>
	);
}

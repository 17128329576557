import React from "react";
import ModalBox from "./ModalBox";

const DeleteModal = ({ isOpen, onCancel, onConfirm }) => {
    if(!isOpen) return null;

	return (
        <ModalBox>
            <h2 className="text-lg font-semibold mb-4">Confirm Delete</h2>
				<p className="mb-4">Are you sure you want to delete this purchase?</p>
				<div className="flex justify-end">
					<button
						className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-1.5 px-4 rounded-md mr-2"
						onClick={onCancel}
					>
						Cancel
					</button>
					<button
						className="bg-red-600 hover:bg-red-700 text-white font-semibold py-1.5 px-4 rounded-md"
						onClick={onConfirm}
					>
						Delete
					</button>
				</div>
        </ModalBox>
    );
};

export default DeleteModal;

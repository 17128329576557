import React from "react";
import ModalBox from "../../../Components/ModalBox";

const WarehouseModdal = ({ isOpen, onCancel, onConfirm, setCurrentWarehouse }) => {
	if (!isOpen) return null;

    const handleWarehouseSelection = (warehouse) => {
        setCurrentWarehouse(warehouse);
        onCancel();
    }

	return (
		<ModalBox>
			<div className="flex justify-between space-x-2">
				<button
					className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-4 w-48"
					onClick={() => handleWarehouseSelection('')}
				>
					All Warehouses
				</button>
				<button
					className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-4 w-48"
					onClick={() => handleWarehouseSelection('0')}
				>
					Mogok
				</button>
				<button
					className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-4 w-48"
					onClick={() => handleWarehouseSelection('1')}
				>
					Mandalay
				</button>
			</div>
		</ModalBox>
	);
};

export default WarehouseModdal;

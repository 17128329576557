import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';

import axiosInstance from "../../../api/axios";
import TextInput from "../../../Components/TextInput";
import DeleteModal from "../../../Components/DeleteModal";
import PurchaseForm from "./PurchaseForm";
import Drawer from "./Drawer";

export default function PurchaseCreate () {
	const [listOfPurchases, setListOfPurchases] = useState([]);

	const [modalOpen, setModalOpen] = useState(false);
	const [drawerOpen, setDrawerOpen] = useState(false); // State for the drawer
	const [selectedPurchase, setSelectedPurchase] = useState(null); // State for the selected purchase
	const [deletePurchaseId, setDeletePurchaseId] = useState(null);

	const [currentPage, setCurrentPage] = useState();
	const [lastPage, setLastPage] = useState();
	const [searchQuery, setSearchQuery] = useState("");

	const handleDeleteModal = (purchaseId) => {
		setDeletePurchaseId(purchaseId);
		setModalOpen(true);
	};

	const handleDelete = async () => {
		try {
			// Ensure deletePurchaseId is being used in the request URL
			await axiosInstance.delete(`/purchases/${deletePurchaseId}`);
			// Refetch the list of purchases after deletion
			fetchPurchases(currentPage, searchQuery);
			setModalOpen(false); // Close the modal after deletion
		} catch (error) {
			console.log("Error deleting purchase.", error);
		}
	};

	const fetchPurchases = async (pageNum = 1, searchQuery = "") => {
		try {
			const response = await axiosInstance.get("/purchases", {
				params: { 
					page: pageNum, 
					search: searchQuery,
				},
			});
			setListOfPurchases(response.data.posts);
			setCurrentPage(response.data.currentPage);
			setLastPage(response.data.lastPage);
		} catch (error) {
			console.log("Error fetching purchases.");
		}
	};

	useEffect(() => {
		fetchPurchases();
	}, []);

	useEffect(() => {
		fetchPurchases(1, searchQuery);
	}, [searchQuery]);

	const handlePageChange = (pageNum) => {
		setCurrentPage(pageNum);
		fetchPurchases(pageNum, searchQuery);
	};

	const renderPageButtons = () => {
		const pageNumbers = [];
		const maxPageButtons = 5;
		let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
		let endPage = Math.min(lastPage, startPage + maxPageButtons - 1);

		if (endPage - startPage < maxPageButtons - 1) {
			startPage = Math.max(1, endPage - maxPageButtons + 1);
		}

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}

		return pageNumbers.map((pageNum) => (
			<button
				key={pageNum}
				onClick={() => handlePageChange(pageNum)}
				className={`px-2 py-1 bg-gray-300 rounded-md text-sm ${
					currentPage === pageNum
						? "bg-gray-900 text-white"
						: "hover:bg-gray-700 hover:text-white text-gray-700"
				}`}
			>
				{pageNum}
			</button>
		));
	};

	const handleViewDetails = (purchase) => {
		setSelectedPurchase(purchase);
		setDrawerOpen(true);
	};

	return (
		<>
			<DeleteModal
				isOpen={modalOpen}
				onCancel={() => setModalOpen(false)}
				onConfirm={handleDelete}
			/>

			<label
				className="text-gray-700 text-lg font-semibold mb-3 block"
				htmlFor="cost"
			>
				Create Purchase
			</label>

			<PurchaseForm
				fetchPurchases={fetchPurchases}
				setSearchQuery={setSearchQuery}
			/>

			<div className="p-4 rounded border border-gray-300 bg-white shadow-md mt-3">
				<TextInput
					type="text"
					className="text-sm py-1 text-gray-700 mb-3"
					placeholder="Search"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
				<table className="w-full text-sm text-left rtl:text-right text-gray-500">
					<thead className="sticky top-0 text-xs text-gray-100 uppercase bg-gray-700">
						<tr>
							<th scope="col" className="px-4 py-2.5 font-semibold w-24">
								Action
							</th>
							<th scope="col" className="px-6 py-2.5 font-semibold w-36">
								Date
							</th>
							<th scope="col" className="px-6 font-semibold w-48">
								Code
							</th>
							<th scope="col" className="px-6 font-semibold w-52">
								Name
							</th>
							<th scope="col" className="px-6 font-semibold text-right w-36">
								Weight (ct)
							</th>
							<th scope="col" className="px-6 font-semibold text-right w-36">
								Qty (pcs)
							</th>
							<th scope="col" className="px-6 font-semibold text-right w-36">
								Cost (MMK)
							</th>
							<th scope="col" className="px-6 font-semibold text-right w-36">
								Warehouse
							</th>
						</tr>
					</thead>
					<tbody>
						{listOfPurchases.length === 0 ? (
							<tr>
								<td colSpan={8} className="text-center py-4">
									No Purchases found.
								</td>
							</tr>
						) : (
							listOfPurchases.map((purchase, index) => (
								<tr
									key={purchase.id}
									className={`bg-white hover:bg-gray-100 border-b ${
										index === 0 && currentPage === 1
											? "bg-yellow-100 font-bold hover:bg-yellow-100"
											: ""
									}`}
								>
									<td className="px-3 space-x-3">
										<button onClick={() => handleDeleteModal(purchase.id)}>
											<CancelPresentationIcon className="text-gray-500 hover:text-red-500" />
										</button>
										<button onClick={() => handleViewDetails(purchase)}>
											<RemoveRedEyeSharpIcon className="text-gray-500 hover:text-gray-600" />
										</button>
									</td>
									<td className="px-6 py-1">{purchase.date}</td>
									<td className="px-6">{purchase.code}</td>
									<td className="px-6">{purchase.name}</td>
									<td className="px-6 text-right">
										{purchase.weight} <span className="ml-2">ct</span>
									</td>
									<td className="px-6 text-right">
										{purchase.quantity} <span className="ml-2">pcs</span>
									</td>
									<td className="px-6 text-right">{purchase.cost}</td>
									<td className="px-6 text-right">
										{purchase.warehouse === "0" ? "Mogok" : "Mandalay"}
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>

				<div className="flex justify-between items-center mt-6">
					{listOfPurchases.length !== 0 && (
						<>
							<span className="text-gray-600 px-6 text-sm">
								Page ( {currentPage} of {lastPage} )
							</span>
							<div className="flex items-center space-x-1.5">
								<button
									onClick={() => handlePageChange(1)}
									className={`px-2 py-1 rounded-md text-sm ${
										currentPage === 1
											? "bg-gray-300 pointer-events-none text-gray-400"
											: "bg-gray-500 hover:bg-gray-700 text-white"
									}`}
								>
									First
								</button>
								<button
									onClick={() => handlePageChange(currentPage - 1)}
									className={`px-2 py-1 rounded-md text-sm ${
										currentPage === 1
											? "bg-gray-300 pointer-events-none text-gray-400"
											: "bg-gray-500 hover:bg-gray-700 text-white"
									}`}
								>
									Previous
								</button>
								{renderPageButtons()}
								<button
									onClick={() => handlePageChange(currentPage + 1)}
									className={`px-2 py-1 rounded-md text-sm ${
										currentPage === lastPage
											? "bg-gray-300 pointer-events-none text-gray-400"
											: "bg-gray-500 hover:bg-gray-700 text-white"
									}`}
								>
									Next
								</button>
								<button
									onClick={() => handlePageChange(lastPage)}
									className={`px-2 py-1 rounded-md text-sm ${
										currentPage === lastPage
											? "bg-gray-300 pointer-events-none text-gray-400"
											: "bg-gray-500 hover:bg-gray-700 text-white"
									}`}
								>
									Last
								</button>
							</div>
						</>
					)}
				</div>
			</div>
			
			{drawerOpen && <Drawer
				isOpen={drawerOpen}
				onClose={() => setDrawerOpen(false)}
				purchase={selectedPurchase}
				fetchPurchases={fetchPurchases}
				currentPage={currentPage}
				searchQuery={searchQuery}
			/>}
		</>
	);
}

// src/Validations/ValidationSchema.js
import * as Yup from 'yup';
import axiosInstance from "../api/axios";

export const purchaseSchema = (originalCode) => Yup.object().shape({
    date: Yup.date().required('Required'),
    warehouse: Yup.string().required('Required'),
    supplier_id: Yup.string().required('Required'),
    code: Yup.string().required('Required').test(
        'unique-code',
        'Code must be unique',
        async (value) => {
            if (!value || value === originalCode) return true; // Skip validation if value is empty or same as original

            try {
                const response = await axiosInstance.get('/purchases/check-code', {
                    params: { code: value },
                });
                return !response.data.exists; // Assume API returns { exists: true/false }
            } catch (error) {
                console.error('Error checking code uniqueness:', error);
                return false; // Return false if there's an error
            }
        }
    ),
    name: Yup.string().required('Required'),
    weight: Yup.number().typeError('Invalid input').required('Required'),
    quantity: Yup.number().typeError('Invalid input').required('Required'),
    cost: Yup.number().typeError('Invalid input').required('Required'),
});

import NavBar from "../../Layout/NavBar";
import LeftSidebar from "../../Layout/LeftSidebar";
import { Outlet } from 'react-router-dom';

export default function Index () {

	const buttons = [
		{ route: "/purchase/create", label: "Create" },
		{ route: "/purchase/report", label: "Report" },
		{ route: "/purchase/history", label: "History" },
	];

	return (
		<div className="min-h-screen bg-gray-100">
			{/* Top Nav Bar */}
			<NavBar title="Purchase" />

			<div className="flex flex-1">
				{/* Left Sidebar */}
				<LeftSidebar buttons={buttons} />

				{/* Main Content */}
				<div className="p-4 flex-1">
                    <Outlet />
				</div>
			</div>
		</div>
	);
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './Layout/Home';
import PurchaseIndex from './Modules/Purchase/Index';
import SaleList from './Modules/Sale/SaleList';
import PurchaseCreateIndex from './Modules/Purchase/Create/Index';
import PurchaseReportIndex from './Modules/Purchase/Report/Index';
import AllPurchase from './Modules/Purchase/AllPurchase';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home/>,
  },
  {
    path: '/purchase',
    element: <PurchaseIndex />,
    children: [
      {
        path: 'create',
        element: <PurchaseCreateIndex />,
      },
      {
        path: 'report',
        element: <PurchaseReportIndex />,
      },
      {
        path: 'history',
        element: <AllPurchase />,
      },
    ],
  },
  {
    path: '/sale',
    element: <SaleList/>,
    children: [
      {
        path: 'create',
        element: <PurchaseCreateIndex />,
      },
      {
        path: 'all',
        element: <AllPurchase />,
      },
    ],
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

export default function NavBar({ title }) {
  const navigate = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const goBack = () => {
    navigate('/');
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleFullScreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  return (
    <nav className="bg-gray-800 text-white p-4 flex items-center h-14">
      <button onClick={goBack} className="text-gray-200 mr-6">
        <ArrowBackIosSharpIcon />
      </button>
      <h1 className="text-xl flex-1">{title}</h1>
      <button onClick={toggleFullScreen} className="text-gray-200">
        {isFullScreen ? <FullscreenExitIcon sx={{ fontSize: 28 }} /> : <FullscreenIcon sx={{ fontSize: 28 }} />}
      </button>
    </nav>
  );
}

import React from "react";
import NavBar from "../../Layout/NavBar";
import LeftSidebar from "../../Layout/LeftSidebar";
import { Outlet } from "react-router-dom";

export default function SaleList() {
	const buttons = [
		{ route: "/sale/create", label: "Create" },
		{ route: "/sale/all", label: "View All" },
	];

	return (
		<div className="min-h-screen flex flex-col bg-gray-200">
			{/* Top Nav Bar */}
			<NavBar title="Sale" />

			<div className="flex flex-1">
				{/* Left Sidebar */}
				<LeftSidebar buttons={buttons} />

				{/* Main Content */}
				<div className="p-4 flex-1">
					<Outlet />
				</div>
			</div>
		</div>
	);
}

import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { useFormik } from "formik";
import axiosInstance from "../../../api/axios";
import { purchaseSchema } from "../../../Validations/ValidationSchema";
import TextInput from "../../../Components/TextInput";
import { WAREHOUSES } from "../../../constants";

const Drawer = ({ isOpen, onClose, purchase, fetchPurchases, currentPage, searchQuery }) => {
    const [loading, setLoading] = useState(true);
    const [listOfSuppliers, setListOfSuppliers] = useState([]);

    const fetchSuppliers = async () => {
        try {
            const response = await axiosInstance.get("/users");
            const filteredSuppliers = response.data.filter(
                (user) => user.user_type === "supplier"
            );
            setListOfSuppliers(filteredSuppliers);
        } catch (error) {
            console.log("Error fetching users.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSuppliers();
    }, []);

    const initialValues = {
        date: purchase.date ? format(new Date(purchase.date), "yyyy-MM-dd") : null,
        warehouse: purchase.warehouse,
        supplier_id: purchase.supplier_id,
        code: purchase.code,
        name: purchase.name,
        weight: purchase.weight,
        quantity: purchase.quantity,
        cost: purchase.cost,
        note: purchase.note,
    };

    const formik = useFormik({
        initialValues,
        validationSchema: purchaseSchema(purchase.code),
        onSubmit: async (values) => {
            try {
              await axiosInstance.put(`/purchases/${purchase.id}`, values);
              fetchPurchases(currentPage, searchQuery);
              onClose();
            } catch (error) {
              console.error('Error updating purchase:', error);
            }
          },
    });

    const handleDateChange = (date) => {
        formik.setFieldValue("date", date ? format(date, "yyyy-MM-dd") : null);
    };

    return (
        <Transition
            show={isOpen}
            enter="transition-transform ease-in-out duration-300"
            enterFrom="transform translate-x-full"
            enterTo="transform translate-x-0"
            leave="transition-transform ease-in-out duration-300"
            leaveFrom="transform translate-x-0"
            leaveTo="transform translate-x-full"
        >
            <div className="fixed inset-0 flex">
                {/* Overlay */}
                <div
                    className={`fixed inset-0 bg-gray-400 bg-opacity-70 transition-opacity duration-300 ${
                        isOpen ? "opacity-100" : "opacity-0"
                    }`}
                    onClick={onClose}
                />
                {/* Drawer Content */}
                <div
                    className={`fixed top-0 right-0 bottom-0 w-2/4 bg-white shadow-lg z-50 ${
                        isOpen ? "translate-x-0" : "translate-x-full"
                    }`}
                >
                    <button
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-700"
                        onClick={onClose}
                    >
                        <CloseSharpIcon sx={{ fontSize: 26 }} />
                    </button>
                    <h2 className="text-xl p-4 font-semibold">
                        Purchase #{purchase.code}
                    </h2>
                    <hr />
                    <div className="p-4">
                        <form className="w-full mt-3 px-4" onSubmit={formik.handleSubmit}>
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                                <div className="col-span-1 h-20">
                                    <label className="block text-sm mb-2" htmlFor="date">
                                        Date
                                    </label>
                                    <DatePicker
                                        className="border border-gray-400 bg-gray-50 text-sm w-full py-1 px-2 text-gray-700 focus:bg-yellow-100 rounded-md shadow-sm"
                                        dateFormat="yyyy/MM/dd"
                                        name="date"
                                        selected={formik.values.date ? new Date(formik.values.date) : null}
                                        onChange={handleDateChange}
                                    />
                                    {formik.errors.date && formik.touched.date && (
                                        <div className="text-red-500 text-xs">{formik.errors.date}</div>
                                    )}
                                </div>
                                <div className="col-span-1 h-20">
                                    <label className="block text-sm mb-2" htmlFor="warehouse">
                                        Warehouse
                                    </label>
                                    <select
                                        className="border border-gray-400 bg-gray-50 w-full text-sm rounded-md shadow-sm py-1 px-1 text-gray-700 focus:bg-yellow-100"
                                        id="warehouse"
                                        name="warehouse"
                                        onChange={formik.handleChange}
                                        value={formik.values.warehouse}
                                    >
                                        {WAREHOUSES.map((warehouse) => (
                                            <option key={warehouse.value} value={warehouse.value}>
                                                {warehouse.label}
                                            </option>
                                        ))}
                                    </select>
                                    {formik.errors.warehouse && formik.touched.warehouse && (
                                        <div className="text-red-500 text-xs">{formik.errors.warehouse}</div>
                                    )}
                                </div>
                                <div className="col-span-1 h-20">
                                    <label className="block text-sm mb-2" htmlFor="supplier">
                                        Supplier
                                    </label>
                                    <select
                                        className="border border-gray-400 bg-gray-50 w-full text-sm rounded-md shadow-sm py-1 px-1 text-gray-700 focus:bg-yellow-100"
                                        id="supplier"
                                        name="supplier_id"
                                        onChange={formik.handleChange}
                                        value={formik.values.supplier_id}
                                    >
                                        {loading && <option value="">Suppliers...</option>}
                                        {!loading && listOfSuppliers.length === 0 && (
                                            <option value="" disabled>
                                                No Suppliers
                                            </option>
                                        )}
                                        {!loading &&
                                            listOfSuppliers.map((supplier) => (
                                                <option key={supplier.email} value={supplier.id}>
                                                    {supplier.name}
                                                </option>
                                            ))}
                                    </select>
                                    {formik.errors.supplier_id && formik.touched.supplier_id && (
                                        <div className="text-red-500 text-xs">{formik.errors.supplier_id}</div>
                                    )}
                                </div>
                                <div className="col-span-1 h-20">
                                    <label className="block text-sm mb-2" htmlFor="code">
                                        Product Code
                                    </label>
                                    <TextInput
                                        id="code"
                                        type="text"
                                        className="mt-1 text-sm bg-gray-50 block w-full"
                                        placeholder="Code"
                                        name="code"
                                        onChange={formik.handleChange}
                                        value={formik.values.code}
                                    />
                                    {formik.errors.code && formik.touched.code && (
                                        <div className="text-red-500 text-xs">{formik.errors.code}</div>
                                    )}
                                </div>
                                <div className="col-span-1 h-20">
                                    <label className="block text-sm mb-2" htmlFor="name">
                                        Product Name
                                    </label>
                                    <TextInput
                                        id="name"
                                        type="text"
                                        className="mt-1 text-sm bg-gray-50 block w-full"
                                        placeholder="Name"
                                        name="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                    {formik.errors.name && formik.touched.name && (
                                        <div className="text-red-500 text-xs">{formik.errors.name}</div>
                                    )}
                                </div>
                                <div className="col-span-1 h-20">
                                    <label className="block text-sm mb-2" htmlFor="weight">
                                        Weight (ct)
                                    </label>
                                    <TextInput
                                        id="weight"
                                        type="text"
                                        className="mt-1 text-sm bg-gray-50 block w-full"
                                        placeholder="Weight"
                                        name="weight"
                                        onChange={formik.handleChange}
                                        value={formik.values.weight}
                                    />
                                    {formik.errors.weight && formik.touched.weight && (
                                        <div className="text-red-500 text-xs">{formik.errors.weight}</div>
                                    )}
                                </div>
                                <div className="col-span-1 h-20">
                                    <label className="block text-sm mb-2" htmlFor="quantity">
                                        Quantity (Pc)
                                    </label>
                                    <TextInput
                                        id="quantity"
                                        type="text"
                                        className="mt-1 text-sm bg-gray-50 block w-full"
                                        placeholder="Quantity"
                                        name="quantity"
                                        onChange={formik.handleChange}
                                        value={formik.values.quantity}
                                    />
                                    {formik.errors.quantity && formik.touched.quantity && (
                                        <div className="text-red-500 text-xs">{formik.errors.quantity}</div>
                                    )}
                                </div>
                                <div className="col-span-1 h-20">
                                    <label className="block text-sm mb-2" htmlFor="cost">
                                        Cost (MMK)
                                    </label>
                                    <TextInput
                                        id="cost"
                                        type="text"
                                        className="mt-1 text-sm bg-gray-50 block w-full"
                                        placeholder="Cost"
                                        name="cost"
                                        onChange={formik.handleChange}
                                        value={formik.values.cost}
                                    />
                                    {formik.errors.cost && formik.touched.cost && (
                                        <div className="text-red-500 text-xs">{formik.errors.cost}</div>
                                    )}
                                </div>
                                <div className="col-span-4 h-20">
                                    <label
                                        className="block text-sm mb-2"
                                        htmlFor="purchase-edit-note"
                                    >
                                        Note
                                    </label>
                                    <textarea
                                        id="purchase-edit-note"
                                        className="border border-gray-400 text-sm w-full rounded-md shadow-sm p-3"
                                        placeholder="Reasons for editing..."
                                        name="note"
                                        onChange={formik.handleChange}
                                        value={formik.values.note || ''}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="flex justify-between mt-10">
                                <div></div>
                                <button
                                    type="submit"
                                    className="ml-2 inline-flex items-center px-4 py-2 bg-indigo-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-indigo-700 disabled:opacity-25 transition ease-in-out duration-150"
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Transition>
    );
};

export default Drawer;


export const USER_TYPES = [
    { value: 'sys_user', label: 'System User' },
    { value: 'sale_person', label: 'Sale Person' },
    { value: 'supplier', label: 'Supplier' },
    { value: 'customer', label: 'Customer' },
];

export const WAREHOUSES = [
    { value: '0', label: 'Mogok' },
    { value: '1', label: 'Mandalay' },
];

export const PAYMENT_PLATFORMS = [
    { value: '0', label: 'Local' },
    { value: '1', label: 'Titok' },
    { value: '2', label: 'Wechat' },
    { value: '3', label: 'Taobao' },
];